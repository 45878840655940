<template>
  <NuxtLink
    class="big-property-card-container"
    :to="`/property/${data.slug}`"
  >
    <div
      class="img-container"
      :style="{
        height: imageHeight,
        backgroundImage: `linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.9) 100%
        ),
        url(${data.thumbnail})`,
      }"
    >
      <div class="img-text">
        <Text
          type="p3"
          weight="bold"
          class="property-type"
        >
          {{ data.category?.name }}
        </Text>
        <Text
          type="h6"
          weight="bold"
        >
          {{ data.name }}
        </Text>
      </div>
    </div>
    <div class="detail-container">
      <div :class="withFooter && 'property-detail-title-container'">
        <Text
          type="h6"
          weight="bold"
          class="property-price"
        >
          {{ formatNumber(data.price, true) }}
        </Text>
        <div v-if="withFooter">
          <button
            class="property-like-btn"
            :disabled="favoriteState.loading"
            @click="handleLikeProperty"
          >
            <component
              :is="loveIcon"
              width="16"
              height="16"
              fill="#333"
            />
          </button>
        </div>
      </div>
      <div class="property-detail">
        <LocationIcon
          width="16"
          height="16"
        />
        <Text
          type="p2"
          :weight="withFooter ? 'regular' : 'bold'"
        >
          {{
            formatAddress({
              districtName: data.district?.name,
              cityName: data.city?.name,
              provinceName: data.province?.name,
            })
          }}
        </Text>
      </div>
      <div class="property-detail-area">
        <div class="property-detail">
          <LandIcon
            width="16"
            height="16"
          />
          <Text
            type="p2"
            :weight="withFooter ? 'regular' : 'bold'"
          >
            Luas Tanah {{ formatNumber(data.surface_area) }}M<sup>2</sup>
          </Text>
        </div>
        <div class="property-detail">
          <BuildingIcon
            width="16"
            height="16"
          />
          <Text
            type="p2"
            :weight="withFooter ? 'regular' : 'bold'"
          >
            Luas Bangunan {{ formatNumber(data.building_area) }}M<sup>2</sup>
          </Text>
        </div>
      </div>

      <UserContact
        v-if="withFooter && data.company"
        is-inline
        class="property-user-contact"
        :data="data.company"
      />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import UserContact from '~/components/common/UserContact.vue'

  import { useFavoriteStore } from '~/store'
  import { formatAddress } from '~/utils'
  import { IProperty } from '~/interfaces'

  import BuildingIcon from '~/assets/icons/building.svg'
  import LandIcon from '~/assets/icons/land.svg'
  import LocationIcon from '~/assets/icons/location.svg'
  import LoveIcon from '~/assets/icons/love.svg'
  import LoveSolidIcon from '~/assets/icons/UserMenu/love-solid.svg'

  interface IBigPropertyCardProps {
    imgHeight?: string
    withFooter?: boolean
    data: IProperty
  }

  const props = defineProps<IBigPropertyCardProps>()

  const favoriteStore = useFavoriteStore()

  const { state: favoriteState } = storeToRefs(favoriteStore)

  const imageHeight = props.imgHeight || '100%'

  const loveIcon = computed(() => {
    if (favoriteStore.isPropertyFaved(props.data.id)) {
      return LoveSolidIcon
    }

    return LoveIcon
  })

  const handleLikeProperty = async (e: Event) => {
    e.preventDefault()

    await favoriteStore.togglePropertyFavorite({
      propertyId: props.data.id,
    })
  }
</script>

<style scoped lang="scss">
  .big-property-card-container {
    display: flex;
    flex-direction: column;

    .img-container {
      position: relative;
      color: $base100;
      background-size: cover;
      background-position: center;

      .img-text {
        position: absolute;
        bottom: 32px;
        left: 32px;
      }

      .property-type {
        background: rgba(255, 255, 255, 0.3);
        padding: 4px 6px;
        margin-bottom: 12px;
        width: fit-content;
      }
    }

    .detail-container {
      border: 1px solid $base500;
      padding: 12px 32px 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .property-detail-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .property-price {
        color: $orange500;
      }

      .property-like-btn {
        padding: 8px;
        border: 1px solid $base400;
        border-radius: 50%;
        cursor: pointer;
        background: $base100;
        display: flex;

        &:hover {
          opacity: 0.75;
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      .property-detail {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .property-detail-area {
        display: flex;

        :first-child.property-detail {
          border-right: 1px solid $base600;
          margin-right: 20px;
          padding-right: 20px;
        }
      }
    }

    .property-user-contact {
      border-top: 1px solid $base500;
      margin-top: 4px;
      padding-top: 16px;
    }
  }

  @media screen and (max-width: ($mobileLarge + 'px')) {
    .big-property-card-container {
      .detail-container {
        .property-detail-area {
          flex-direction: column;
          gap: 12px;

          :first-child.property-detail {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
</style>
