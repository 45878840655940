<template>
  <div class="property-autocomplete-container">
    <div class="input-container">
      <label
        v-if="isShowLabel"
        for="searchInput"
      >
        <Text
          type="p3"
          weight="bold"
        >
          Cari
        </Text>
      </label>
      <div class="input-search">
        <SearchIcon
          width="16"
          height="16"
          fill="#333333"
        />
        <input
          id="searchInput"
          type="text"
          placeholder="Masukkan lokasi/nama properti"
          :value="selectedSuggestion ? selectedSuggestion.name : search"
          @input="(e: Event) => emits('setSearch', (e.target as HTMLInputElement).value)"
        />
      </div>

      <div
        v-if="search && !isHideSuggestion"
        class="suggestion-container"
      >
        <div
          v-for="(suggestion, idx) in suggestions"
          :key="idx"
          class="suggestion-item"
          @click="() => emits('chooseSuggestion', suggestion)"
        >
          <div>
            <Text
              type="p2"
              weight="bold"
              class="name"
            >
              {{ suggestion.name }}
            </Text>
            <Text
              type="p2"
              weight="bold"
              class="detail"
            >
              {{ suggestion.detail }}
            </Text>
          </div>
          <Text
            type="p3"
            weight="bold"
            class="type"
          >
            {{ suggestion.type }}
          </Text>
        </div>
      </div>
    </div>
    <Button
      v-if="isShowButton"
      class="btn-search"
      @click="onClickSearch"
    >
      Cari
      <ArrowIcon
        v-if="isButtonArrow"
        width="24"
        height="24"
        fill="#FFF"
        transform="rotate(180)"
      />
    </Button>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import ArrowIcon from '~/assets/icons/arrow.svg'
  import SearchIcon from '~/assets/icons/search.svg'

  import { useMasterStore } from '~/store'
  import { IPropertyAutocompleteSuggestion } from '~/interfaces'

  interface IPropertyAutocompleteProps {
    isButtonArrow: boolean
    isShowButton: boolean
    isShowLabel: boolean
    isHideSuggestion?: boolean
    search: string
    selectedSuggestion: IPropertyAutocompleteSuggestion | null
  }

  interface IPropertyAutocompleteEmits {
    (e: 'chooseSuggestion', value: IPropertyAutocompleteSuggestion): void
    (e: 'clickSearch'): void
    (e: 'setSearch', value: string): void
  }

  const props = defineProps<IPropertyAutocompleteProps>()
  const emits = defineEmits<IPropertyAutocompleteEmits>()

  const masterStore = useMasterStore()
  const { state: masterState } = storeToRefs(masterStore)

  const suggestions: ComputedRef<IPropertyAutocompleteSuggestion[]> = computed(
    () => {
      const provincesFiltered: IPropertyAutocompleteSuggestion[] = []
      const citiesFiltered: IPropertyAutocompleteSuggestion[] = []
      const districtsFiltered: IPropertyAutocompleteSuggestion[] = []
      const villagesFiltered: IPropertyAutocompleteSuggestion[] = []

      masterState.value.provinces.forEach((province) => {
        if (province.name.toLowerCase().includes(props.search.toLowerCase())) {
          provincesFiltered.push({
            queryKey: 'province_id',
            id: province.id,
            name: province.name,
            type: 'Provinsi',
            detail: 'Indonesia',
          })
        }
      })

      masterState.value.cities.forEach((city) => {
        if (city.name.toLowerCase().includes(props.search.toLowerCase())) {
          citiesFiltered.push({
            queryKey: 'regency_id',
            id: city.id,
            name: city.name,
            type: 'Area',
            detail: city.province.name,
          })
        }
      })

      masterState.value.districts.forEach((district) => {
        if (district.name.toLowerCase().includes(props.search.toLowerCase())) {
          districtsFiltered.push({
            queryKey: 'district_id',
            id: district.id,
            name: district.name,
            type: 'Area',
            detail: district.regency.name,
          })
        }
      })

      masterState.value.villages.forEach((village) => {
        if (village.name.toLowerCase().includes(props.search.toLowerCase())) {
          villagesFiltered.push({
            queryKey: 'village_id',
            id: village.id,
            name: village.name,
            type: 'Area',
            detail: village.district.name,
          })
        }
      })

      return [
        ...provincesFiltered,
        ...citiesFiltered,
        ...districtsFiltered,
        ...villagesFiltered,
      ]
    },
  )

  const onClickSearch = () => {
    emits('clickSearch')
  }
</script>

<style scoped lang="scss">
  .property-autocomplete-container {
    display: flex;
    position: relative;
    width: 100%;
    .input-container {
      color: $base800;
      padding: 16px 24px;
      background: $base100;
      width: 100%;

      p {
        margin-bottom: 2px;
      }
    }

    .input-search {
      display: flex;
      align-items: center;
      gap: 12px;

      input {
        width: 240px;
        outline: none;
        border: none;
        width: 100%;

        &::placeholder {
          color: $base700;
        }
      }
    }

    .suggestion-container {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 16px;
      background: $base100;
      max-height: 300px;
      overflow-y: scroll;
      border: 1px solid $base400;
      z-index: map-get($map: $zIndexMap, $key: propertyAutocomplete);

      .suggestion-item {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
        cursor: pointer;
        border-bottom: 1px solid $base400;

        &:not(:first-child) {
          border-top: none;
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          opacity: 0.75;
        }

        .name {
          color: $base900;
        }

        .detail {
          color: $base700;
        }

        .type {
          color: $blue500;
          background: $base300;
          padding: 4px 6px;
          height: fit-content;
          backdrop-filter: blur(10px);
        }
      }
    }

    .btn-search {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
</style>
