import { IPropertyAutocompleteSuggestion } from '~/interfaces'

export const usePropertySearchAutocomplete = () => {
  const search = ref('')
  const selectedSuggestion = ref<IPropertyAutocompleteSuggestion | null>(null)

  const handleSetSearch = (value: string) => {
    selectedSuggestion.value = null
    search.value = value
  }

  const handleClickSuggestion = (value: IPropertyAutocompleteSuggestion) => {
    search.value = ''
    selectedSuggestion.value = value
  }

  return {
    search,
    selectedSuggestion,
    handleSetSearch,
    handleClickSuggestion,
  }
}
